import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PrivatePagesGuardService } from './service/private-pages-guard.service';

const routes: Routes = [
	{ path: '', loadChildren: () => import('./pages/root/root.module').then((m) => m.RootModule), pathMatch: 'full' },
	{
		path: 'privacy',
		loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
	},
	{
		path: 'about',
		loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutModule),
	},
	{
		path: 'sound-on',
		loadChildren: () => import('./pages/sound-on/sound-on.module').then((m) => m.SoundOnModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: '2years',
		loadChildren: () => import('./pages/two-years/two-years.module').then((m) => m.TwoYearsModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'frank',
		loadChildren: () => import('./pages/frank/frank.module').then((m) => m.FrankModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'suffering',
		loadChildren: () => import('./pages/suffering/suffering.module').then((m) => m.SufferingModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'hard-work',
		loadChildren: () => import('./pages/hardwork/hardwork.module').then((m) => m.HardworkModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'unconventional-path',
		loadChildren: () => import('./pages/unconventional-path/unconventional-path.module').then((m) => m.UnconventionalPathModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'master',
		loadChildren: () => import('./pages/master/master.module').then((m) => m.MasterModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'clippings',
		loadChildren: () => import('./pages/clippings/clippings.module').then((m) => m.ClippingsModule),
		canActivate: [],
	},
	{
		path: 'writings',
		loadChildren: () => import('./pages/writings/writings.module').then((m) => m.WritingsModule),
	},
	{
		path: 'projects',
		loadChildren: () => import('./pages/projects/projects.module').then((m) => m.ProjectsModule),
	},
	{
		path: 'decisions',
		loadChildren: () => import('./pages/decisions/decisions.module').then((m) => m.DecisionsModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'talk-is-cheap',
		loadChildren: () => import('./pages/talk-is-cheap/talk-is-cheap.module').then((m) => m.TalkIsCheapModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'tennis-1',
		loadChildren: () => import('./pages/tennis-1/tennis-1.module').then((m) => m.Tennis1Module),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'tennis-2',
		loadChildren: () => import('./pages/tennis-2/tennis-2.module').then((m) => m.Tennis2Module),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'gradient-descent',
		loadChildren: () => import('./pages/gradient-descent/gradient-descent.module').then((m) => m.GradientDescentModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'coffee',
		loadChildren: () => import('./pages/coffee/coffee.module').then((m) => m.CoffeeModule),
	},
	{
		path: 'erosion',
		loadChildren: () => import('./pages/erosion/erosion.module').then((m) => m.ErosionModule),
		canActivate: [PrivatePagesGuardService],
	},
	{
		path: 'engineering-principles',
		loadChildren: () => import('./pages/engineering-principles/engineering-principles.module').then((m) => m.EngineeringPrinciplesModule),
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
